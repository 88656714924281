import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import '../../assets/css/header.css';
import discord from '@assets/images/discord-i.svg';
import twitter from '@assets/images/twitter-i.svg';
import telegram from '@assets/images/telegram-i.svg';
import SocialMenu from '../SocialMenu/index';

const Header = () => {
    const [hamburgerStatus, setHamburgerStatus] = useState(false);
    return (

        <div className="sticky top-0 z-20 bg-background-gray">
            <div className={`flex w-full justify-between px-10 relative py-3 items-center ${hamburgerStatus ? 'active' : ''
                }`}>
                <div className="logo z-20">
                    <a href='#' >
                        <img src={Logo} alt="Logo" className="w-3/5 md:w-4/5" />
                    </a>
                </div>
                <div className="hamburger relative"
                    onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                    <span />
                </div>
                <div className="menu flex items-start lg:items-center">
                    <ul className="flex flex-col lg:flex-row items-center">
                        {/* <li className="py-3 w-full lg:w-auto lg:py-0 "><a className="text-white mx-8" href='/'>Home</a></li> */}
                        {/* <li className="py-3 w-full lg:w-auto lg:py-0" onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                            <a target="_blank" className="text-white mx-8 font-bold hover:text-custom-violet" href='https://medium.com/@verko.io'>Blog</a>
                        </li> */}

                        <li className="py-2 w-full lg:w-auto lg:py-0" onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                            <a className="text-white mx-8 font-bold hover:text-custom-violet" href='#features'>Features</a>
                        </li>

                        <li className="py-2 w-full lg:w-auto lg:py-0" onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                            <a className="text-white mx-8 font-bold hover:text-custom-violet" href='#how-it-work'>How it works</a></li>

                        <li className="py-2 w-full lg:w-auto lg:py-0" onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                            <a className="text-white mx-8 font-bold hover:text-custom-violet" href='#games'>Games</a></li>

                        <li className="py-2 w-full lg:w-auto bg-indigo-600 rounded-2xl hover:text-white hover:bg-indigo-700 cursor-pointer " onClick={() => window.open("https://wallet.verko.io")}>
                            <a className="text-white mx-8 font-bold hover:text-white"  >App</a></li>
                        {/* <li className="py-3 w-full lg:w-auto lg:py-0" onClick={() => setHamburgerStatus(!hamburgerStatus)}>
                            <a className="text-white mx-8 font-bold hover:text-custom-violet" href='#contactus'>Contact us</a></li> */}
                        {/* <li className="py-3 w-full lg:w-auto lg:py-0 "><a className="block btn btn--purple text-white mx-8" href='/'>Whitepaper</a></li> */}
                        {/* <div className='flex mt-5 lg:mt-0'>
                            <li className="py-3 w-full lg:w-auto lg:py-0">
                                <a className="text-white" target="_blank" href='https://discord.gg/verko'> <img src={discord} className="w-8 mx-2" /></a>
                            </li>
                            <li className="py-3 w-full lg:w-auto lg:py-0">
                                <a className="text-white" target="_blank" href='https://t.me/Verko_Chat'> <img src={telegram} className="w-8 mx-2" /></a>
                            </li>
                            <li className="py-3 w-full lg:w-auto lg:py-0">
                                <a className="text-white" target="_blank" href='https://twitter.com/Verko_Platform'> <img src={twitter} className="w-8 mx-2" /></a>
                            </li>
                        </div> */}
                    </ul>
                </div>
            </div>
        </div>

    );
};
export default Header;