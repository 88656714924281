import React from 'react';
import Header from '@/components/Header/index';
import VideoBanner from '@/components/VideoBanner/index';
// import IconList from '@/components/IconList/index';
// import UserJourney from '@/components/UserJourney/index';
import GameSection from '@/components/GameSection/index';
// import Team from '@/components/Team/index';
import Footer from '@/components/Footer/index';
// import Advisor from '@/components/Advisor/index';
// import Founders from '@/components/Founders/index';
// import Newsletter from '@/components/Newsletter/index';
// import Partners from '@/components/Partners/index';
import BoxContent from '@/components/BoxContent/index';
import GridContent from '@/components/GridContent/index';
import PhotoContent from '@/components/PhotoContent/index';
import ComingSoon from '@/components/ComingSoon/index';
// import NewsAndBlogs from '@/components/NewsAndBlogs/index';
import Accordion from '@/components/Accordion/index';
import SocialMenu from '@/components/SocialMenu/index';

const LandingPage = () => {
    return (
        <div id="home" >
            <Header />
            <VideoBanner />
            <BoxContent />
            <GridContent />
            <PhotoContent />
            {/* <IconList /> */}
            {/* <UserJourney /> */}
            <GameSection />
            <ComingSoon />
            {/* <NewsAndBlogs /> */}
            {/* <Founders /> */}
            {/* <Team /> */}
            {/* <Advisor /> */}
            {/* <Partners />  */}
            <Accordion />
            {/* <ContactUs /> */}
            <Footer />
            <SocialMenu />
        </div>
    );
}
export default LandingPage;