import React from 'react';
import photoContent1 from '../../assets/images/photo-content-1.png';
import photoContent2 from '../../assets/images/photo-content-2.png';
import photoContentDecor1 from '../../assets/images/photo-content-decor-1.svg';
import photoContentDecor2 from '../../assets/images/photo-content-decor-2.svg';

const PhotoContent = () => {
    return (
        <div>
            <div className="photo-content relative pb-24">

                <img src={photoContentDecor1} alt="" className="absolute top-1/4 right-0 z-0" />
                <img src={photoContentDecor2} alt="" className="absolute bottom-0 left-0 z-0" />
                <div className=" wrapper">
                    <div className="photo-content__container">
                        <div className="photo-content__item flex flex-col lg:flex-row-reverse items-center py-8">
                            <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:ml-8">
                                <img src={photoContent1} alt="" className="w-4/5 lg:w-auto mx-auto" />
                            </div>
                            <div className="w-full lg:w-1/2 text-center lg:text-left lg:mr-8">
                                <h2 className="text-white text-3xl md:text-4xl lg:text-5xl font-Inter-700 font-bold mb-5">NFT Marketplace</h2>
                                <div className="text-custom-gray-3 text-base md:text-xl font-Inter-500">
                                    <p>Verko is a social hub for of web3 games and projects! Players can access games for free by matching with NFT holders from different collections and communities. The NFT titles can be used across games and projects.</p>
                                </div>
                                <ul className="check text-custom-gray text-base mt-5">
                                    <li>Free access to a wide range of web3 games and projects
                                    </li>
                                    <li>
                                        Enable liquidity opportunities for NFT hodlers & Games
                                    </li>
                                    <li>
                                        Lending Marketplace
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="photo-content__item flex flex-col lg:flex-row items-center py-8">
                            <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:mr-8">
                                <img src={photoContent2} alt="" className="w-4/5 lg:w-auto mx-auto" />
                            </div>
                            <div className="w-full lg:w-1/2 text-center lg:text-left lg:ml-8">
                                <h2 className="text-white text-3xl md:text-4xl lg:text-5xl font-Inter-700 font-bold mb-5">NFT Interoperability</h2>
                                <div className="text-custom-gray-3 text-base md:text-xl font-Inter-500">
                                    <p>Verko aims to fulfill the NFT promise of interoperability in Web 3 games and projects. Our protocol allows NFT collections and the community to define the Bootstrapping standards in the blockchain gaming ecosystem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default PhotoContent;
