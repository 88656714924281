import React from 'react';
import work1 from '../../assets/images/work-1.svg';
import work2 from '../../assets/images/work-2.svg';
import work3 from '../../assets/images/work-3.svg';
import work4 from '../../assets/images/work-4.svg';
import workImg1 from '../../assets/images/work-img-1.png';
import workImg2 from '../../assets/images/work-img-2.png';
import workImg3 from '../../assets/images/work-img-3.png';
import workImg4 from '../../assets/images/work-img-4.png';
import gridContentDecor from '../../assets/images/grid-content-decor.svg';
import workDecor1 from '../../assets/images/work-decor-1.svg';
import workDecor2 from '../../assets/images/work-decor-2.svg';
import '../../assets/css/gridcontent.css';

const GridContent = () => {
    return (
        <div id="how-it-work">
            <div className="gridcontent relative">
                <img src={gridContentDecor} alt="" className="absolute top-1/3 left-0 z-0" />
                <div className=" wrapper py-16">
                    <h2 className="text-white font-Inter-700 text-3xl md:text-4xl lg:text-5xl font-bold mb-10 md:mb-20">How it works</h2>
                    <div className="gridcontent__container grid gap-20 grid-cols-1 lg:grid-cols-2 items-center">
                        <div className="gridcontent__item relative w-full">
                            <img src={workDecor1} className="absolute left-0 bottom-0" alt="" />
                            <div className="flex flex-wrap p-8">
                                <div className="flex w-full flex-col-reverse md:flex-row">
                                    <div className="w-full md:w-1/2 pb-8">
                                        <img src={work1} alt="" className="mx-auto md:mx-0" />
                                    </div>
                                    <div className="w-full md:w-1/2 relative mb-4 md:mb-0">
                                        <img src={workImg1} alt="work 1" className="md:absolute md:bottom-0 md:-right-8 w-3/5 md:w-auto mx-auto md:mx-0" />
                                    </div>
                                </div>
                                <div className="w-full  text-white opacity-80 text-center md:text-left">
                                    <h3 className="font-Inter-900 font-black text-xl md:text-2xl mb-1">Onboarding</h3>
                                    <p className="font-Inter-500 font-semibold text-base md:text-xl">From account creation to token management, Verko allows investors and players to manage all their assets with our Internal Wallet Management System</p>
                                </div>
                            </div>
                        </div>
                        <div className="gridcontent__item relative w-full">
                            <img src={workDecor2} className="absolute right-0 bottom-0" alt="" />
                            <div className="flex flex-wrap p-8">
                                <div className="flex w-full flex-col-reverse md:flex-row">
                                    <div className="w-full md:w-1/2 pb-8">
                                        <img src={work2} alt="" className="mx-auto md:mx-0" />
                                    </div>
                                    <div className="w-full md:w-1/2 relative mb-4 md:mb-0">
                                        <img src={workImg2} alt="work 2" className="md:absolute md:bottom-0 md:-right-8 w-3/5 md:w-auto mx-auto md:mx-0" />
                                    </div>
                                </div>
                                <div className="w-full  text-white opacity-80 text-center md:text-left">
                                    <h3 className="font-Inter-900 font-black text-xl md:text-2xl mb-1">API Integration</h3>
                                    <p className="font-Inter-500 font-semibold text-base md:text-xl">With all the necessary tools for the Web 3 gaming infrastructure, developers can easily create or port existing titles to the blockchain with our secure API
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="gridcontent__item relative w-full">
                            <img src={workDecor1} className="absolute left-0 bottom-0" alt="" />
                            <div className="flex flex-wrap p-8">
                                <div className="flex w-full flex-col-reverse md:flex-row">
                                    <div className="w-full md:w-1/2 pb-8">
                                        <img src={work3} alt="" className="mx-auto md:mx-0" />
                                    </div>
                                    <div className="w-full md:w-1/2 relative mb-4 md:mb-0">
                                        <img src={workImg3} alt="work 3" className="md:absolute md:bottom-0 md:-right-8 w-3/5 md:w-auto mx-auto md:mx-0" />
                                    </div>
                                </div>
                                <div className="w-full  text-white opacity-80 text-center md:text-left">
                                    <h3 className="font-Inter-900 font-black text-xl md:text-2xl mb-1">Community</h3>
                                    <p className="font-Inter-500 font-semibold text-base md:text-xl">Backed by industry leaders and experts, Verko connects our partners with the biggest guilds and communities to ensure a project’s long term success</p>
                                </div>
                            </div>

                        </div>
                        <div className="gridcontent__item relative w-full">
                            <img src={workDecor2} className="absolute right-0 bottom-0" alt="" />
                            <div className="flex flex-wrap p-8">
                                <div className="flex w-full flex-col-reverse md:flex-row">
                                    <div className="w-full md:w-1/2 pb-8">
                                        <img src={work4} alt="" className="mx-auto md:mx-0" />
                                    </div>
                                    <div className="w-full md:w-1/2 relative mb-4 md:mb-0">
                                        <img src={workImg4} alt="work 4" className="md:absolute md:bottom-0 md:-right-8 w-3/5 md:w-auto mx-auto md:mx-0" />
                                    </div>
                                </div>
                                <div className="w-full  text-white opacity-80 text-center md:text-left">
                                    <h3 className="font-Inter-900 font-black text-xl md:text-2xl mb-1">Marketplace</h3>
                                    <p className="font-Inter-500 font-semibold text-base md:text-xl">Connecting NFT hodlers and gamers with full spectrum of web3 games! Verko allows gamers to have direct access to in-game NFT assets through the marketplace</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridContent;