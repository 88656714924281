import React from 'react';
import AccordionItem from './AccordionItem/index';
import '../../assets/css/accordion.css';

const Accordion = () => {
    const data = [
        {
            title: 'Why Elrond and not a different blockchain?',
            content: 'Elrond’s dedicated effort in sustainability aligned with Verko’s vision to build a better future with technology. It is a high-speed Proof of Stake network that is 6 million times more energy-efficient than Bitcoin which overcompensates for its modest carbon footprint by giving back to the planet through investments in third-party sustainability projects, biodiversity protection, and social programs. Till now, Elrond has already offset 7,407,000 kilograms of CO2 which is 25% more than its network accounts for with a range of additional social and environmental benefits.',
        },
        {
            title: "What is Verko's impact on the web3 community?",
            content: 'Verko fosters web3 gaming development by decoding the complexity and setting the standards. The layer 2 gaming protocol provides developers and founders with easy-to-use web3 API integrations and promising free-to-join liquidity opportunities and NFT utilities for investors and players.',
        },
        {
            title: 'How can gamers connect to our game on Verko platform?',
            content: 'Since Verko is designed for universal accessibility, your game will not be limited to users of specific chains (Binance, Solana, etc), but will allow any user to join without creating a wallet or having one already, all they need to do is to sign-up / create an account on Verko.',
        },
        {
            title: 'As a Game Developer, do I need to have previous experience with integrating games to Web3?',
            content: "No, you don't need previous experience, Verko's API Tools & SDKs handle everything from Smart Contracts, and Token Management to Web3 Game integration.",
        },
        // {
        //     title: 'How does Verko compare to other solutions?',
        //     content: "The blockchain ecosystem desperately needs scalability in different applications to make its utility mainstream. With Verko, we offer secured and quick access to web3 gaming scalability to everyone without forcing any parties to compromise on the security of their assets.",
        // },
    ]
    return (
        <div className="accordion">
            <div className="wrapper">
                <div className="accordion__container pb-8">
                    <h2 className="text-white text-3xl md:text-4xl lg:text-5xl font-inter-700 mb-6 md:mb-12 mt-20">FAQ</h2>
                    {data.map((x, index) => {
                        const ind = index;
                        return <AccordionItem key={ind} title={x.title} content={x.content} />
                    })}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
