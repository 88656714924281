import React from 'react';
import video from '../../assets/images/banner.mp4';
import bannerImg from '../../assets/images/banner-img-1.png';
import bannerDecor from '../../assets/images/banner-decor.svg';
import circle1 from '../../assets/images/circle-1.svg';
import circle2 from '../../assets/images/circle-2.svg';
import '../../assets/css/banner.css';

const VideoBanner = () => {
    return (
        <div>

            <div className="video-banner relative text-center mb-4 md:mb-0 md:-mt-36">

                <video autoPlay loop muted className="w-full">
                    <source src={video} type="video/mp4" />
                </video>

                <div className="video-banner__text -mb-1 w-full md:absolute md:bottom-0 py-8 md:py-14 h-1/4">
                    <div className="w-10/12 md:w-full lg:w-7/12 mx-auto">
                        <h1 className='text-white font-inter-200 text-2xl md:text-3xl lg:text-5xl leading-normal'>{'Introducing Metaverse Experience Framework'}</h1>
                    </div>
                </div>
            </div>
            <div className="banner bg-no-repeat bg-cover bg-center flex items-center relative">
                <div className='wrapper flex flex-col lg:flex-row items-center'>
                    <img src={bannerDecor} alt="" className="absolute left-0 bottom-0" />
                    <img alt="" src={circle1} className="circle-1 absolute z-0 right-0 w-1/4" />
                    <div className="w-full lg:w-1/2 flex flex-col items-start mt-6 lg:mt-0 mb-4 md:mb-0">
                        <span className=" w-full uppercase text-custom-violet text-xl md:text-3xl font-inter-900 mb-2 block text-center md:text-left">Layer 2</span>
                        <h1 className="text-white font-inter-900 text-2xl md:text-3xl lg:text-5xl font-black text-center md:text-left mb-4 md:mb-6 w-full">{'Gaming platform'}</h1>
                        <div className=" text-center md:text-left mb-12 text-custom-gray text-xl font-inter-400">
                            {'Verko is a Layer 2 Gaming Protocol that introduces the first ever Metaverse Experience Framework (MEF), allowing developers to easily port new or existing titles onto the blockchain. Our opensource protocol allows NFT collections and the community to define the standards of which NFTs can be used in between gaming projects.'}
                        </div>
                        {/* <div className="flex flex-col md:flex-row items-start w-full">
                            <a href="/" className='text-white btn btn--small btn--solid btn--purple mr-6 w-full md:w-auto md:mb-0 mb-4'>Get Started</a>
                            <a href="/" className='text-white btn btn--small btn--solid btn--black btn--border w-full md:w-auto'>Learn More</a>
                        </div> */}
                    </div>
                    <div className="w-full lg:w-1/2 relative">
                        <img alt="" src={circle2} className="absolute z-0 bottom-0" />
                        <img alt="Banner" src={bannerImg} className="z-10 w-4/5 lg:w-auto mx-auto" />
                    </div>
                </div>
            </div>
        </div >
    );
};
export default VideoBanner;