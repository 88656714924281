import React from 'react';
import boxContent from '../../assets/images/box-content.svg';
import boxContentDecor from '../../assets/images/box-content-decor.svg';
import boxContentDecorGen from '../../assets/images/box-content-decor-gen.svg';
import boxContentDecorCircle from '../../assets/images/box-content-decor-circle.svg';
import '../../assets/css/boxcontent.css';

const BoxContent = () => {
    return (
        <div id="features" className="-mt-36 pt-36">
            <div className="boxcontent relative">
                <img src={boxContentDecorCircle} alt="" className="absolute top-0 left-0 z-0" />
                <img src={boxContentDecorGen} alt="" className="absolute top-1/3 right-0 z-0" />
                <div className=" wrapper">
                    <div className="boxcontent__container flex flex-col-reverse lg:flex-row items-center">
                        <img src={boxContentDecor} alt="" className="absolute bottom-0 left-0 z-0" />
                        <div className="w-full lg:w-1/2 z-10">
                            <img src={boxContent} alt="" />
                        </div>
                        <div className="w-full lg:w-1/2 boxcontent__textbox text-left z-10 px-4 md:px-10 lg:px-0 xl:pr-10 py-12 pb-0 lg:py-8 xl:py-10">
                            <h2 className="font-inter-700 text-center md:text-left text-2xl md:text-4xl text-white mb-4">API Integration</h2>
                            <div className="boxcontent__text text-left text-custom-gray text-base md:text-xl font-inter-400">
                                <p>
                                    No more developing your own smart contracts for Web3.
                                    Verko’s full-suite API not only offsets the carbon footprint with our world-class certified climate-conscious partners from Elrond but also provides developers with all the necessary functionality for blockchain integration.
                                </p>
                                <ul className="check">
                                    <li>Blockchain wallet connection</li>
                                    <li>NFT collection issuing and minting</li>
                                    <li>NFT purchases and marketplace</li>
                                    <li>Token generation</li>
                                    <li>In-game currency management</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxContent;