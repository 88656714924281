import React, { useState } from 'react'
import MdAdd from '@material-ui/icons/Add';
import MdClose from '@material-ui/icons/Clear';
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa"
import {
  MainButton,
  ChildButton,
  FloatingMenu,
  Directions,
} from 'react-floating-button-menu';
import 'react-floating-button-menu/dist/index.css';

function SocialMenu() {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <div className="fixed bottom-10 right-10">
      <FloatingMenu
        slideSpeed={500}
        isOpen={isOpen}
        spacing={8}
        direction={Directions.Up}
      >
        <div className='md:hidden'>
          <MainButton
            className="bg-custom-border-gray"
            isOpen={isOpen}
            iconResting={<MdAdd className="text-white text-xl" />}
            iconActive={<MdClose className="text-white text-xl" />}

            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            size={56}
          />
        </div>
        <ChildButton
          className="bg-custom-violet"
          icon={<FaDiscord className="text-white text-xl" />}
          size={40}
          onClick={() => window.open("https://discord.com/invite/ej3tzQWX4J")}
        />
        <ChildButton
          className="bg-custom-violet"
          icon={<FaTelegramPlane className="text-white text-xl" />}
          size={40}
          onClick={() => window.open("https://t.me/Verko_Chat")}
        />
        <ChildButton
          className="bg-custom-violet"
          icon={<FaTwitter className="text-white text-xl" />}
          size={40}
          onClick={() => window.open("https://twitter.com/Verko_Platform")}
        />
      </FloatingMenu>
    </div>
  )
}

export default SocialMenu