import React, { useState } from 'react';
import '../../assets/css/gamesection.css';
import glogo from '@assets/images/cantina-logo-v3.png';
import gvideo from '@assets/images/app-loader-bg.png';
import gvideo1 from '@assets/images/cantina-video.svg';
import play from '@assets/images/play-btn.svg';
import 'react-modal-video/css/modal-video.min.css';
import ModalVideo from 'react-modal-video'

const GameSection = () => {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div id="games" className="gamesection text-white font-inter py-16 md:py-32">
                <div className="wrapper grid gap-8 lg:gap-16 grid-cols-1 lg:grid-cols-2 justify-between">
                    <div className="gamesection-container text-center md:text-left w-full px-4 md:px-12 py-10 md:py-16">
                        <h2 className="text-2xl md:text-4xl font-inter-900 mb-6 md:mb-14">{'Cantina Royale'}</h2>
                        <p className="font-inter-900 mb-4">{'A showcase game on the Verko platform.'}</p>
                        <p className="text-sm text-gray-400 mb-10">{'A top-down tactical arcade shooter game supporting PvP and PvE modes.'}</p>
                        <h3 className="font-inter-900 text-2xl mb-4">{'Applied Technology'}</h3>
                        <p className="text-sm text-gray-400 mb-4">{'Cantina leveraged Verko API in its development with the following key features and add-ons:'}</p>
                        <ul className="pl-8 mb-12 text-left">
                            <li className="text-sm text-gray-400 mb-4">{'A user-friendly sign up flow with Verko’s internal wallet management system'}</li>
                            <li className="text-sm text-gray-400 mb-4">{'Free to play and NFT Lending Marketplace'}</li>
                            <li className="text-sm text-gray-400 mb-4">{'3rd party NFT collections integration (Bored Apes Yacht Club)'}</li>
                        </ul>
                        <a className="btn btn--purple w-full inline-block text-center text-sm" href="https://cantinaroyale.io" target="_blank">{'Learn More'}</a>
                    </div>
                    <div className="w-full">
                        <img src={glogo} alt="" className="mx-auto lg:-mt-24" />
                        <button onClick={() => setOpen(true)} className="bg-video bg-cover w-full h-80 rounded-3xl border-4 border-blue-600">
                            {/* <img src={gvideo} alt="" className="mx-auto rounded-xl" /> */}
                            <img src={play} alt="" className="w-24 mx-auto"
                            />
                        </button>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="OICGUdHnXfc" onClose={() => setOpen(false)} />
        </>
    );
}
export default GameSection;