import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';

const AccordionItem = ({title, content}) => {
    const [status, setStatus] = useState(false);
    const handleAccordion = () => {
        setStatus(!status);
    };
    return (
        <div className="accordion__item mb-6 lg:mb-8">
            <div className="accordion__header flex cursor-pointer items-center justify-between py-6 px-8 text-left" onClick={handleAccordion}>
                <h2 className="text-base md:text-lg text-white font-inter-600">{title}</h2>
                <div className={`accordion__math ${status ? 'active' : ''}`}>

                </div>
            </div>
            <SmoothCollapse expanded={status} heightTransition=".4s ease">
                <div className="accordion__content px-8 text-base md:text-lg text-white opacity-40 pb-6 text-left">
                    <p>{content}</p>
                </div>
            </SmoothCollapse>
        </div>
    );
};

export default AccordionItem;
