import React from 'react';
import logo from '@assets/images/logo.png';
import discord from '@assets/images/discord-i.svg';
import twitter from '@assets/images/twitter-i.svg';
import telegram from '@assets/images/telegram-i.svg';
import '../../assets/css/footer.css';

const Footer = () => {
    return (
        <div className="footer pt-16 md:pt-24 pb-6 md:pb-14">
            <div className="wrapper flex flex-col justify-between h-full font-inter">
                <div className="flex flex-col lg:flex-row mb-8 md:mb-14">
                    <div className="w-full lg:w-1/3 text-center md:text-left mb-10 lg:mb-0">
                        <img className="mb-4 mx-auto md:mx-0" src={logo} alt="Footer Logo" />
                        <span className="text-white text-base md:text-lg">
                            With Verko’s APIs, integrating your game onto the exciting world of blockchain and web3.0 is easier than ever.
                        </span>
                    </div>
                    <div className="w-full lg:w-1/3 text-white text-center md:text-left flex flex-col md:flex-row justify-between lg:justify-end">

                        <div className="mb-6 md:mb-0 ">
                            <span className="font-inter-600 mb-2 block">{'Legal'}</span>
                            <ul>
                                <li className="text-gray-400 mb-1"><a target={"_blank"} href="/privacypolicy.html">{'Privacy Policy'}</a></li>
                                <li className="text-gray-400 mb-1"><a target={"_blank"} href="/terms.html">{'Terms and Conditions'}</a></li>
                                <li className="text-gray-400 mb-1"><a target={"_blank"} href="/legal-disclaimer.html">{'Legal Disclaimer'}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 text-white text-center md:text-left flex flex-col md:flex-row justify-between lg:justify-end">
                        <div className="mb-6 md:mb-0 md:mr-14 xl:mr-20">
                            <span className="font-inter-600 mb-2 block">{'Socials'}</span>
                            <ul>
                                <li className="text-gray-400 mb-1 flex  items-center ">
                                    <img className='w-8' src={telegram} />
                                    <a href="https://t.me/Verko_Chat" target="_blank" className="pl-2">{'Telegram'}</a>
                                </li>

                                <li className="text-gray-400 mb-1 flex  items-center ">
                                    <img className='w-8' src={twitter} />
                                    <a href="https://twitter.com/Verko_Platform" target="_blank" className="pl-2">{'Twitter'}</a>
                                </li>
                                <li className="text-gray-400 mb-1 flex  items-center ">
                                    <img className='w-8' src={discord} />
                                    <a href="https://discord.com/invite/ej3tzQWX4J"
                                        target="_blank" className="pl-2">{'Discord'}</a>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="mb-6 md:mb-0 md:mr-14 xl:mr-20">
                            <span className="font-inter-600 mb-2 block">{'Blockchain'}</span>
                            <ul>
                                <li className="text-gray-400 mb-1"><a href="/">{'CoinMarketGap'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'CoinGecko'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'Opensea'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'BSCScan'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'Pancakeswap'}</a></li>
                            </ul>
                        </div> */}
                        {/* <div className="mb-6 md:mb-0 ">
                            <span className="font-inter-600 mb-2 block">{'Useful Links'}</span>
                            <ul>
                                <li className="text-gray-400 mb-1"><a href="/">{'Contract'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'Pooppaper'}</a></li>
                                <li className="text-gray-400 mb-1"><a href="/">{'Contact'}</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="text-center md:text-left">
                    <span className="text-white text-base md:text-lg">{`© ${new Date().getFullYear()} Verko`}</span>
                </div>
            </div>

        </div>
    );
}
export default Footer;