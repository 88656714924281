import React from 'react';
import logo from '@assets/images/logo.svg';
import '../../assets/css/comingsoon.css';
import photoContentDecor2 from '../../assets/images/photo-content-decor-2.svg';
import comingSoonCircle from '../../assets/images/coming-soon-circle.svg';


const ComingSoon = () => {
    return(
        <div className="coming-soon relative py-16">
            <img src={photoContentDecor2} alt="" className="absolute top-1/2 left-0 z-0" />
            <img src={comingSoonCircle} alt="" className="absolute top-1/2 right-0 z-0" />
            <div className="wrapper">
                <div className="coming-soon__header mb-6 md:mb-12">
                    <h2 className="text-white text-3xl md:text-4xl lg:text-5xl font-inter-700 mb-3">{'Coming Soon'}</h2>
                    <p className="text-lg md:text-xl text-custom-gray-3 font-Inter-500">Stay tuned for the next games to be release on the Verko platform</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="coming-soon__item py-16 md:p-24">
                        <div className="coming-soon__imgbox text-center relative w-full md:w-auto">
                            <img src={logo} alt="" className="mx-auto w-3/5 md:w-auto" />
                            <span className="absolute top-1/3 w-full left-0 text-3xl text-custom-gray-2">Coming Soon</span>
                        </div>
                    </div>
                    <div className="coming-soon__item py-16 md:p-24">
                        <div className="coming-soon__imgbox text-center relative w-full md:w-auto">
                            <img src={logo} alt="" className="mx-auto w-3/5 md:w-auto" />
                            <span className="absolute top-1/3 w-full left-0 text-3xl text-custom-gray-2">Coming Soon</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ComingSoon;